// import { OrderForm } from "~/pageScripts/order/new"
// import { QuestionnaireForm } from "~/types/graphql"

export const PREFECTURES = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
]

// export const editorInit = {
//   language: "ja",
//   selector: "textarea#full-featured-non-premium",
//   plugins:
//     "print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
//   imagetools_cors_hosts: ["picsum.photos"],
//   deprecation_warnings: false,
//   menubar: "file edit view insert format tools table help",
//   formats: {
//     blue_marker: {
//       inline: "span",
//       merge_siblings: false,
//       exact: false,
//       attributes: { class: "blue-marker" },
//     },
//     red_marker: {
//       inline: "span",
//       merge_siblings: false,
//       exact: false,
//       attributes: { class: "red-marker" },
//     },
//     yellow_marker: {
//       inline: "span",
//       merge_siblings: false,
//       exact: false,
//       attributes: { class: "yellow-marker" },
//     },
//   },
//   /* eslint-disable */
//   // At this stage, any is allowed because the Editor type of @type/tinymce and the Editor that actually passes to the argument are different.
//   setup: (editor: any) => {
//     editor.ui.registry.addToggleButton("blue_marker", {
//       text: "青マーカー",
//       onAction: (): void => {
//         editor.execCommand("mceToggleFormat", false, "blue_marker")
//       },
//       onSetup: (api: any) => {
//         editor.formatter.formatChanged("blue_marker", (state: boolean) => {
//           api.setActive(state)
//         })
//       },
//     })
//     editor.ui.registry.addToggleButton("card_link", {
//       text: "カードリンク",
//       onAction: (): void => {
//         editor.windowManager.open({
//           title: "カードリンクの挿入",
//           body: {
//             type: "panel",
//             items: [
//               {
//                 type: "input",
//                 name: "cardLink",
//                 inputMode: "text",
//                 label: "カードリンク化したいURLを入力してください。",
//               },
//             ],
//           },
//           buttons: [
//             {
//               type: "submit",
//               text: "OK",
//             },
//           ],
//           onSubmit: function (api: any) {
//             var data = api.getData()

//             editor.execCommand(
//               "mceInsertContent",
//               false,
//               "<iframe class='link_card' style='width:100%; height: 160px;' src=" +
//                 process.env.BASE_URL +
//                 "/embed?url=" +
//                 data.cardLink +
//                 "></iframe>",
//             )
//             api.close()
//           },
//         })
//       },
//     })
//     editor.ui.registry.addToggleButton("red_marker", {
//       text: "赤マーカー",
//       onAction: (): void => {
//         editor.execCommand("mceToggleFormat", false, "red_marker")
//       },
//       onSetup: (api: any): void => {
//         editor.formatter.formatChanged("red_marker", (state: boolean) => {
//           api.setActive(state)
//         })
//       },
//     })
//     editor.ui.registry.addToggleButton("yellow_marker", {
//       text: "黄マーカー",
//       onAction: (): void => {
//         editor.execCommand("mceToggleFormat", false, "yellow_marker")
//       },
//       onSetup: (api: any): void => {
//         editor.formatter.formatChanged("yellow_marker", (state: boolean) => {
//           api.setActive(state)
//         })
//       },
//     })
//   },
//   /* eslint-enable */
//   toolbar:
//     "undo redo | bold italic underline strikethrough | blue_marker red_marker yellow_marker | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link card_link anchor codesample | ltr rtl",
//   toolbar_sticky: true,
//   autosave_ask_before_unload: true,
//   autosave_interval: "30s",
//   autosave_prefix: "{path}{query}-{id}-",
//   autosave_restore_when_empty: false,
//   autosave_retention: "2m",
//   image_advtab: true,
//   link_default_target: "_blank",
//   link_list: [
//     { title: "My page 1", value: "https://www.tiny.cloud" },
//     { title: "My page 2", value: "http://www.moxiecode.com" },
//   ],
//   image_list: [
//     { title: "My page 1", value: "https://www.tiny.cloud" },
//     { title: "My page 2", value: "http://www.moxiecode.com" },
//   ],
//   image_class_list: [
//     { title: "None", value: "" },
//     { title: "Some class", value: "class-name" },
//   ],
//   importcss_append: true,
//   templates: [
//     {
//       title: "gym_box",
//       description: "gym_boxのテンプレート",
//       content:
//         "<div class='gym_box'><div class='gym_box_contents'><a class='gym_box_img' href='ここにリンク'><br /><img class='_gym_img' src='https://kencoco-pro.s3.amazonaws.com/articles/ytvjfghqt6-1635844540034.jpg' alt='' width='160' height='76' /><br /></a><div class='gym_box_table'><dl><dt>コース名</dt><dd>ここにコース名</dd><dt>入会金</dt><dd>ここに入会金</dd><dt>コース料金</dt><dd>ここにコース料金</dd><dt>合計額</dt><dd>ここに合計額</dd></dl><a class='_cta_btn' href='ここにリンク' target='_blank' rel='noopener'>公式サイトはこちら</a></div></div></div><p></p>",
//     },
//   ],
//   height: 600,
//   image_caption: true,
//   quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
//   noneditable_noneditable_class: "mceNonEditable",
//   toolbar_mode: "sliding",
//   contextmenu: "link image imagetools table",
//   content_style: `
//     body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }
//     .blue-marker { border-bottom: 4px solid #CADFFD; }
//     .red-marker { border-bottom: 4px solid #ffb8b9; }
//     .yellow-marker { border-bottom: 4px solid #FEFD9C; }
//     .s__article__ctaBtn {
//       margin: 1rem 0;
//       border-radius: 5px;
//       background: #4062bb;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       color: #fff;
//       position: relative;
//       line-height: 1.2;
//       min-height: 50px;
//       text-decoration: none;
//     }
//     .s__article__ctaBtn::before {
//       background: #0a2463;
//       position: absolute;
//       border-radius: 5px;
//       left: 0;
//       bottom: -4px;
//       content: "";
//       display: block;
//       width: 100%;
//       height: 100%;
//       z-index: -1;
//     }
//     .top_sellers_ranking {
//       display: block;
//       text-align: center;
//       padding: 0.5rem 1rem;
//       text-decoration: none;
//       color: #fff;
//       border-bottom: solid 4px #627295;
//       border-radius: 3px;
//       margin: 0.5rem;
//     }
//     .top_sellers_ranking_amazon {
//       background-color: #eb8c11;
//       border-bottom: 5px solid #b86e0d;
//     }
//     .top_sellers_ranking_rakuten {
//       background-color: #bf0000;
//       border-bottom: 5px solid #8c0000;
//     }
//     .top_sellers_ranking_yahoo {
//       background-color: #4070ff;
//       border-bottom: 5px solid #0c53a9;
//     }
//     blockquote {
//       position: relative;
//       padding: 0.5rem 1rem;
//       box-sizing: border-box;
//       font-style: italic;
//       color: #464646;
//       border: solid 3px #72ccf4;
//       p {
//         margin: 1rem 0;
//       }
//     }
//     .c-box {
//       border: 2px solid;
//       border-radius: 0.2em;
//       padding: 1.25rem;
//       margin: 1rem auto;
//       line-height: 1.7;
//     }
//     .c-box--blue {
//       background-color: #d4f3ff;
//       border-color: #82c8e2;
//       color: #465d65;
//     }
//     .c-box--pink {
//       background-color: #ffeeee;
//       border-color: #f7b2b2;
//       color: #775454;
//     }
//     td {
//       padding: 0.25rem 0.5rem;
//     }
//     `,
//   extended_valid_elements: "script[src|async|defer|type|charset]",
// }

// export const S3_REGION = "ap-northeast-1"

// export const ProductTagTypesList = [
//   { key: "scene", value: "シーン" },
//   { key: "worry", value: "お悩み" },
// ]

// // const
export type DefaultConstType = {
  key: string
  value: string
}

// // export const QuestionSiteUnderstands = [
// //   "分かりにくい",
// //   "ちょっと分かりにくい",
// //   "ふつう",
// //   "分かりやすい",
// //   "とても分かりやすい",
// // ]

// // export const QuestionSiteUsefuls = [
// //   "使いにくい",
// //   "ちょっと使いにくい",
// //   "ふつう",
// //   "使いやすい",
// //   "とても使いやすい",
// // ]

// ]

export const ProductPartLayoutTypes = [
  "img_block",
  // "thumb_title_box",
  "left_thumb_normal_box",
  "right_thumb_normal_box",
  "detail_title_box",
  "point_box",
  "list_block",
  "movie_block",
  "pdf_link",
] as const

// export const ArticlePartLayoutTypes = [
//   "htmleditor_content",
//   "ranking_table",
//   "supervisor_comment",
//   "tab_title",
// ] as const

export const DiscountMethods = ["percentage", "fixed_amount"] as const
export const DiscountMethodsMap: {
  key: (typeof DiscountMethods)[number]
  value: string
}[] = [
  { key: "percentage", value: "%" },
  { key: "fixed_amount", value: "円" },
]

// export const ArticleType = ["normal_article", "ranking_article"] as const

// export const ProductTagType = ["worry", "scene", "place"] as const

export const SupervisorProfession = ["doctor", "athlete"] as const

// export const UserReceiveMail = ["all", "denied"] as const
// export const CouponPatternTargetTable = ["category", "product", "sku"] as const
// export const CouponPatternOperand = ["and", "or"] as const
// export const CouponPatternUnit = ["type", "quantity"] as const

export const PaymentTypeMap = {
  credit_card_batch: "クレジットカード",
  amazon_pay: "Amazon Pay",
  cash_on_delivery: "代金引換",
  cash_in_shop: "店舗決済",
  deferred_pay: "後払い",
}

export const CategoryIconList: DefaultConstType[] = [
  { key: "sole", value: "/images/site/journal/index/category_icon/sole.svg" },
  { key: "finger_and_nail", value: "/images/site/journal/index/category_icon/finger_and_nail.svg" },
  { key: "head_and_neck", value: "/images/site/journal/index/category_icon/head_and_neck.svg" },
  { key: "foot", value: "/images/site/journal/index/category_icon/foot.svg" },
  { key: "shoulder", value: "/images/site/journal/index/category_icon/shoulder.svg" },
  { key: "waist", value: "/images/site/journal/index/category_icon/waist.svg" },
  { key: "knee", value: "/images/site/journal/index/category_icon/knee.svg" },
  { key: "others", value: "/images/site/journal/index/category_icon/others.svg" },
  { key: "back", value: "/images/site/journal/index/category_icon/back.svg" },
  { key: "hip_joint", value: "/images/site/journal/index/category_icon/pelvis.svg" },
  { key: "sports", value: "/images/site/journal/index/category_icon/sports.svg" },
  { key: "mask", value: "/images/site/journal/index/category_icon/mask.svg" },
  { key: "muscle_training", value: "/images/site/journal/index/category_icon/muscle_training.svg" },
]

export const ProductCategoryMediaTypes = ["goods", "columns"] as const

export const ProductCategoryPublishDestinations = ["kencoco", "media", "site"] as const

export const GiftPatternType = ["bag", "box", "message", "card", "shopper"] as const

// export const ShippingReferredType = ["standard_delivery", "specified_delivery"] as const

// export const ContactTypeList = [
//   { key: 1, value: "製品に関する質問" },
//   { key: 2, value: "配送状況に関する質問" },
//   { key: 3, value: "返品、交換に関する質問" },
//   { key: 4, value: "ご注文内容に関する質問" },
//   { key: 6, value: "記事に関する質問" },
//   { key: 5, value: "その他" },
// ]

// export const SiteContactTypeList = [
//   { key: 1, value: "製品に関する質問" },
//   { key: 2, value: "配送状況に関する質問" },
//   { key: 3, value: "返品、交換に関する質問" },
//   { key: 4, value: "ご注文内容に関する質問" },
//   { key: 6, value: "インソールの返金トライアルに関する質問" },
//   { key: 5, value: "その他" },
// ]

// export const ProductNameList = [
//   { key: 1, value: "TENTIAL INSOLE" },
//   { key: 2, value: "TENTIAL INSOLE Lite" },
//   { key: 3, value: "TENTIAL INSOLE for BUNISESS" },
//   { key: 4, value: "TENTIAL INSOLE for JUNIOR" },
//   { key: 5, value: "TENTIAL GOLF INSOLE" },
//   { key: 6, value: "TENTIAL GOLF INSOLE +CARBON" },
//   { key: 7, value: "TENTIAL SOCKS" },
//   { key: 8, value: "TENTIAL MASK" },
//   { key: 9, value: "その他" },
// ]

// export const PurchaseChannelList = [
//   { key: 1, value: "Amazon.co.jp" },
//   { key: 2, value: "楽天市場" },
//   { key: 3, value: "Yahoo!ショッピング" },
//   { key: 4, value: "TENTIAL公式オンラインストア" },
//   { key: 5, value: "実店舗" },
//   { key: 6, value: "その他" },
// ]

// export const ReceiveMail = [
//   { option: "受け取らない", value: "denied" },
//   { option: "受け取る", value: "all" },
// ]

// export const LAYOUT_TYPES = [
//   "top_section",
//   "blank",
//   "point",
//   "image",
//   "image_and_text",
//   "video_and_text",
//   "video",
//   "evaluation",
//   "image_slide",
//   "modal_banner",
//   "modal_banner_image",
//   "cart",
//   "multi_cart",
//   "text",
//   "accordion",
// ]

export const BODY_PARTS_SLUG = [
  "sole",
  "finger_and_nail",
  "foot",
  "shoulder",
  "waist",
  "knee",
  "hip_joint",
  "head_and_neck",
  "back",
  "others",
]

// export const FELMAT_PROMOTION_ID = "L6227A"
// export const FELMAT_PROMOTION_IDS = ["L6227A", "A6463K"] as const

// export const ShopCompanyType = ["corporation", "sole_proprietor"] as const

export const NotDeliveryDate = [
  "12-29",
  "12-30",
  "12-31",
  "01-01",
  "01-02",
  "01-03",
  "01-04",
  "01-05",
  "01-06",
  "01-07",
  "01-08",
  "01-09",
]

// export const ReferralSources = [
//   "知人の紹介",
//   "インフルエンサー等の紹介",
//   "広告",
//   "インターネット検索",
//   "SNS",
//   "プレゼント",
//   "イベントや展示会",
//   "テレビ番組、ラジオ番組",
//   "店舗(新丸ビル、渋谷パルコ等)で見かけた",
// ]

// export const questionnaireTypes = ["purchase", "offline_store"]

// セルフラッピングページへの動線を表示するか
export const VISIBLE_SELF_WRAPPING_PAGE = true
export const SELF_WRAPPING_PAGE_URL = "/features/tential-gift-service"
